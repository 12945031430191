import React from 'react'
import { storyblokEditable, StoryblokComponent } from '@storyblok/react';

export default function CustomComponents({ blok }) {
  return (
    <div className={blok.className || ''} {...storyblokEditable(blok)}>
      {blok.content ? blok.content.map(nestedBlok => <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />) : null}
    </div>
  )
}
